import { removeAction, addAction, TextAction } from "./node-actions";
import { Input } from "./input";

export class Node extends Input {
  public id: string;
  public actions: any[];
  public buttons: any[];

  constructor() {
    super();
    this.id = this.generateId("NODE");
    this.actions = [];
    this.buttons = [];
    this.addAction(new TextAction());
  }

  removeAction({ id }: { id: any }): void {
    if (this.actions.length > 1) this.actions = removeAction(this.actions, id);
  }

  addAction(action: any): void {
    action.nodeId = this.id;
    this.actions = addAction(this.actions, action);
  }

  generateId(name: string): string {
    return name + "_" + Math.random().toString(36).substr(2, 9);
  }
}
