import { VueWizard } from "@/vue-wizard";
import { Component } from "vue-property-decorator";
import { BotNode } from "@/app/models/bot-node";
import AdminNavbar from "@/app/components/admin-navbar/AdminNavbar.vue";
import NodeVue from "@/app/components/flows-constructor/flow-node/FlowNode.vue";
import NavBar from "@/app/components/shared/nav-bar/NavBar.vue";

@Component({
    name: "flows-management",
    components: {
        Node: NodeVue,
        AdminNavbar,
        NavBar
    }
})
export default class FlowsManagement extends VueWizard {
    public nodes: any[] = [];
    public connections: any[] = [];
    public tempLine = false;
    public tempElementLine = false;

    mounted(): void {
        const nodo1 = new BotNode("Nuevo nodo1");
        nodo1.position.left = 100;
        nodo1.position.top = 200;
        const nodo2 = new BotNode("Nuevo nodo2");
        nodo2.position.left = 400;
        nodo2.position.top = 400;
        this.nodes.push(nodo1, nodo2);
    }

    addNode(): void {
        const nodo1 = new BotNode("Nuevo nodo1");
        nodo1.position.left = 350;
        nodo1.position.top = 100;
        this.nodes.push(nodo1);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    createConnection(el: any): void {
        if (!this.tempLine) {
            const idString = el.getAttribute("data-id");
            if (idString) {
                const existConnection = this.connections.find(
                    (e: any) => e.action === idString
                );
                if (!existConnection) {
                    const tempCursor = document.createElement("div");
                    const position = el.getBoundingClientRect();
                    tempCursor.setAttribute(
                        "style",
                        `
              width: 10px;
              position: absolute;
              background: red;
              top: ${position.y}px;
              left: ${position.x + 20}px;
            `
                    );
                    const editor: any = this.$refs.editor;
                    editor.appendChild(tempCursor);
                    const line: any = {
                        el, tempCursor, line: {
                            color: "#27ae60",
                            endPlug: "arrow3"
                        }
                    };
                    function move(e: any) {
                        tempCursor.style.top = e.pageY - 20 + "px";
                        tempCursor.style.left = e.pageX + "px";
                        line.position();
                    }

                    let coolFunction = () => {
                        line.remove();
                        editor.removeChild(tempCursor);
                        this.tempLine = false;
                        editor.removeEventListener("mousemove", move);
                        editor.removeEventListener("mouseup", coolFunction);
                    };

                    editor.addEventListener("mouseup", coolFunction);
                    editor.addEventListener("mousemove", move);
                    this.tempLine = true;
                    this.tempElementLine = el;
                }
            }
        }
    }

    makeConnection(
        { actionElement, nodeElement, actionId, nodeId, nodeSocket, draggable }:
            { actionElement: any, nodeElement: any, actionId: any, nodeId: any, nodeSocket: any, draggable: any }
    ): void {
        const nodeRef: any = this.$refs[nodeSocket];
        const existConnection = this.connections.find((e: any) => e.action === actionId);

        if (!existConnection && nodeRef) {
            const node = nodeRef[0];
            const line = {
                actionElement, nodeElement, line: {
                    color: "#27ae60",
                    endPlug: "arrow3"
                }
            };
            const connection: any = {
                action: actionId,
                node: nodeId,
                line
            };
            if (!node.$data.draggable._lines) {
                node.$data.draggable._lines = [];
            }
            node.$data.draggable._lines = node.$data.draggable._lines.concat(line);
            node.$data.draggable.onMove = () => {
                node.$data.draggable._lines.forEach((element: any) => element.position());
            };

            if (!draggable._lines) {
                draggable._lines = [];
            }
            draggable._lines = draggable._lines.concat(line);
            draggable.onMove = () => {
                draggable._lines.forEach((element: any) => element.position());
            };
            this.connections = this.connections.concat(connection);
        }
    }

    onCreateAction(action: any, vueComponent: any): any {
        const existConnection = this.connections.find((connection: any) =>
            connection.action.includes(`${action.nodeId},ACTION`)
        );
        if (existConnection && vueComponent) {
            const line = existConnection.line;
            line.start = vueComponent.$el;
            line.position();
            this.connections = this.connections.map(connection => {
                if (connection.action === existConnection.action && connection.nodeId === existConnection.nodeId) {
                    return { ...connection, action: vueComponent.$el.id };
                }
                return connection;
            });
        }
    }

}