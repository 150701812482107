import { Output } from "./output";

export class Action extends Output {
  public type: string;
  public id: string;
  public nodeId: string;

  constructor(type = "TEXT") {
    super();
    this.type = type;
    this.id = this.generateId();
    this.nodeId = "";
  }

  generateId(): string {
    return "ACTION_" + Math.random().toString(36).substr(2, 9);
  }
}

export class TextAction extends Action {
  public value: string;
  constructor() {
    super("TEXT");
    this.value = "Valor por defecto";
  }
}

export const removeAction = (actions: any, id: any): any => {
  return actions.filter((action: any) => action.id !== id);
};

export const addAction = (actions: any, action: any): any => {
  return actions.concat(action);
};
